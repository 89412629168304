<template>
     <b-modal
      id="table-cbhpm-modal"
      size="lg"
      hide-header
      hide-footer
      centered
      no-stacking
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">Importar tabela CBHPM</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="8">
                    <b-form-group>
                        <label>Nome da tabela</label>
                        <b-form-input v-model="name" />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label>Comunicado oficial</label>
                        <multiselect
                            id="cbhpm_announcement"
                            label="label"
                            track-by="value"
                            v-model="cbhpm_announcement"
                            :options="cbhpmAnnouncements"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar um tipo"
                            class="with-border"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>


            <div 
                class="drop-area" 
                :class="{ disabled: !name || !cbhpm_announcement || uploading || validating }"
                @drop="dropFile" 
                @click="$refs.FileInput.click()"
                @dragover.prevent 
                @drop.prevent
            >
                <div v-if="!name || !cbhpm_announcement">Informe um nome e comunicado oficial para importar</div>
                <div v-else>
                    <div v-if="validating">
                        <b-spinner
                            small
                            variant="light"
                            type="grow"
                        ></b-spinner>
                        Validando dados, isso pode demorar um pouco...
                    </div>
                    <div v-else-if="!file">Clique aqui ou solte o arquivo nessa área para selecionar...</div>
                    <div v-else-if="file" class="file-wrapper">
                        <FileIcon class="file-icon" />
                        <span  class="file-name">{{ file.name }}</span>
                        <div class="toggle-wrapper" @click="$event => $event.stopPropagation()">
                            <toggle-button
                                :width="35"
                                v-model="canUpdate"
                                :sync="true"
                                :height="22"
                                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                            />
                            <span>
                                Atualizar itens existentes
                            </span>
                        </div>
                        <b-button
                            variant="primary"
                            :disabled="uploading || validating || errors.length > 0"
                            @click="!errors.length ? uploadFile($event) : null"
                        >
                            <b-spinner
                                v-if="uploading"
                                small
                                variant="light"
                                type="grow"
                            ></b-spinner>
                            {{ !uploading ? 'Enviar' : 'Enviando...' }}
                        </b-button>
                    </div>
                    <input
                        class="d-none"
                        ref="FileInput"
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        @change="dropFile"
                    />
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import api from '@tables/api';
import readXlsxFile from 'read-excel-file';
import { getCurrentClinic } from '@/utils/localStorageManager';

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        FileIcon: () => import('@tables/assets/icons/file.svg')
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            uploading: false,
            file: null,
            validating: false,
            fechingCsvTemplate: false,
            cbhpmAnnouncements: [],
            name: 'CBHPM ',
            cbhpm_announcement: null,
            canUpdate: false,
        }
    },
    created() {
        this.getCBHPMAnnouncements()
    },
    methods: {
        close() {
            this.$bvModal.hide('table-cbhpm-modal')
        },
        onHidden() {
            // this.form = {}
            this.$emit('hidden')
        },
        async getCBHPMAnnouncements() {
            try {
                const { data } = await this.api.getCBHPMAnnouncements();
                Object.keys(data).forEach((key) => {
                    this.cbhpmAnnouncements.push({ label: key, value: key })
                })
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        async dropFile(e) {
            const files = e.target.files || e.dataTransfer.files
            this.file = files[0]
            this.validating = true
            this.errors = []
            try {
                const rows = await readXlsxFile(files[0])
                console.log(rows);
                // this.errors = validateXlsImport(this.selectedType.value, rows)
                if (this.errors.length){
                    this.file = null
                }
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validating = false
            }
        },
        async uploadFile($event) {
            $event.stopPropagation()
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('name', this.name)
            formData.append('cbhpm_announcement', this.cbhpm_announcement.value)
            formData.append('canUpdate', this.canUpdate)
            formData.append('clinicId', this.clinic.id)
            this.uploading = true
            try {
                await api.importCbhpmXlsx(formData)
                this.$toast.success('Arquivo enviado com sucesso')
                this.file = null
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.uploading = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
  .content {
        padding: 1rem;
        .template-wrapper {
            width: 100%;
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            gap: 10px;
            margin-bottom: 1rem;
        }
        .drop-area {
            width: 100%;
            height: 300px;
            border: 1px dashed var(--neutral-200);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                color: var(--type-placeholder);
            }
            .file-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
            .file-icon {
                width: 60px;
                height: 60px;
            }
            .toggle-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
        .error-wrapper {
            margin-top: 1rem;
            font-size: 0.8rem;
            color: var(--orange);
            max-height: 400px;
            overflow-y: auto;
        }
    }
</style>
<style lang="scss">
#table-cbhpm-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>